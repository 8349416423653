export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾大酒店（中国餐厅）"])},
  "hongkong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["香港楼"])},
  "menu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菜单"])},
    "soups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汤类"])},
    "appetizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前餐"])},
    "dimSums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点心类"])},
    "meats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肉类"])},
    "seaFoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["海鲜类"])},
    "vegetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蔬菜类"])},
    "mixedPlates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拼盘"])},
    "garnishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主食"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["甜点"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐厅的介绍"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布达佩斯中餐厅"])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的餐厅于1990年营业至今，香港楼是我们来到布达佩斯开的第一家中餐厅，台湾大酒楼是第二家中餐厅，一直营业至今。也是匈牙利最早最大的中餐厅之一，我们提供来自中国的各种美食，欢迎您的家人与朋友一起来品尝和享用各种美食。"])}
  },
  "gallery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐厅图片"])},
    "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食物"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐厅"])},
    "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正宗北京烤鸭"])},
    "93cut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正宗北京烤鸭"])},
    "137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西芹腰果"])},
    "145a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["干锅花菜"])},
    "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红烧肉"])},
    "162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大虾炒面"])},
    "16b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黄油煎虾串"])},
    "83a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宫保鸡丁"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拉皮"])},
    "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青田袍"])},
    "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["三文鱼"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["油焖笋"])},
    "99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多宝鱼"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关系"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐厅位于1097 Budapest, Albert Flórián út 3/b 距离Nagyvárad tér 地铁站口步行几分钟。餐厅周边都可以付费停车 工作日18:00以后及周末可以免费停车。"])},
    "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定"])},
    "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定餐桌"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["营业时间"])},
    "kitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["厨房工作时间 22:30-ig"])},
    "everyday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每天营业时间"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])}
  },
  "admin": {
    "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菜单编辑器"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他看着我"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登陆"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮箱"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
    "item": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新物品"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["项目编辑"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匈语"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["物种"])}
    }
  }
}