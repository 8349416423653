<template>
  <div id="NavBar">
    <nav class="navbar navbar-expand-md bg-primary navbar-dark py-3">
      <div class="container">
        <a class="navbar-brand text-white" href="#" @click.prevent="$router.push(`/${this.$i18n.locale}`)">{{ $t('name') }}</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navmenu">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navmenu">
          <ul class="navbar-nav ms-auto justify-content-end">
            <li class="nav-item px-2">
              <a class="nav-link text-white" href="#" @click.prevent="$router.push(`/${this.$i18n.locale}/menu`)">{{ $t('menu.title') }}</a>
            </li>
            <li class="nav-item px-2">
              <a class="nav-link text-white" href="#" @click.prevent="$router.push(`/${this.$i18n.locale}/gallery`)">{{ $t('gallery.title') }}</a>
            </li>
            <li class="nav-item px-2">
              <a class="nav-link text-white" href="#" @click.prevent="$router.push(`/${this.$i18n.locale}/contact`)">{{ $t('contact.title') }}</a>
            </li>
            <li class="nav-item px-2">
              <a class="nav-link text-white" href="https://hongkongrestaurant.hu/">{{ $t('hongkong') }}</a>
            </li>
            <li class="nav-item d-md-none">
              <a class="nav-link">
                <span class="text-white">{{ $i18n.locale.toUpperCase() }}</span>
              </a>
            </li>
            <li class="nav-item d-md-none" v-if="$i18n.locale !== 'hu'"><a class="dropdown-item text-white px-4 py-1" href="#" @click="setLocale('hu')">HU</a></li>
            <li class="nav-item d-md-none" v-if="$i18n.locale !== 'en'"><a class="dropdown-item text-white px-4 py-1" href="#" @click="setLocale('en')">EN</a></li>
            <li class="nav-item d-md-none" v-if="$i18n.locale !== 'zh'"><a class="dropdown-item text-white px-4 py-1" href="#" @click="setLocale('zh')">ZH</a></li>
            <li class="nav-item dropdown d-none d-md-block">
              <button class="btn btn-primary text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {{ $i18n.locale.toUpperCase() }}
              </button>
              <ul class="dropdown-menu bg-primary w-100">
                <li v-if="$i18n.locale !== 'hu'"><a class="dropdown-item text-white text-center" @click="setLocale('hu')">HU</a></li>
                <li v-if="$i18n.locale !== 'en'"><a class="dropdown-item text-white text-center" @click="setLocale('en')">EN</a></li>
                <li v-if="$i18n.locale !== 'zh'"><a class="dropdown-item text-white text-center" @click="setLocale('zh')">ZH</a></li>
              </ul>
            </li>
            <li class="nav-item" v-if="isLoggedIn">
              <a class="nav-link" href="#" @click="logout">{{ $t('admin.logout') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { signOut } from 'firebase/auth'
import { auth } from "@/components/firebaseInit"

export default {
  name: 'NavBar',
  data() {
    return {
      isLoggedIn: false,
      currentUser: null
    }
  },
  created() {
    if(auth.currentUser) {
      this.isLoggedIn = true
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
      this.$router.push({
        params: { locale: locale }
      })
    },
    logout() {
      signOut(auth)
      .then(() => {
        alert('You are logged out!')
        this.$router.go({
          path: '/'
        })
      })
      .catch(error => {
        alert(error.message);
      })
    }
  }
}
</script>