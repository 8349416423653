<template>
  <div id="google-map">
    <section>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2696.6835080327437!2d19.088963415804265!3d47.47659660494247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dce129d09161%3A0x6bc8ef34a672fe53!2sTaiwan%20Restaurant!5e0!3m2!1sen!2shu!4v1663517598599!5m2!1sen!2shu" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </section>
  </div>
</template>

<script>
export default {
  name: 'google-map',
  components: {
  }
}
</script>