<template>
  <div id="app">
    <Navbar />
    <router-view />
    <Map />
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/NavBar';
import Footer from './components/FooterBar';
import Map from './components/GoogleMap';
export default {
  name: 'app',
  components: {
    Navbar,
    Footer,
    Map
  }
}
</script>
