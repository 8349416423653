export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taiwan Étterem"])},
  "hongkong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong Étterem"])},
  "menu": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étlap"])},
    "soups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levesek"])},
    "appetizers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Előételek"])},
    "dimSums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dim sums"])},
    "meats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Húsok"])},
    "seaFoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tengeri ételek"])},
    "vegetables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zöldségek"])},
    "mixedPlates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vegyestálak"])},
    "garnishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köretek"])},
    "desserts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desszertek"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Történetünk"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hagyományos Kínai étterem Budapesten."])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éttermünket több mint 31 évvel ezelőtt, 1991-ben nyitottuk meg, mely az egyik legnagyobb kínai étterem Magyarországon. A kínai ételek széles választékát kínáljuk Kína minden gasztronómiai régiójából, amelyeket kínai mester szakácsok készítenek. A kínai étkezésben fantasztikus élményt nyújt, hogy a társaságodban lévő családtagokkal, barátokkal egyszerre tudtok kóstolni, fogyasztani sokféle ételt."])}
  },
  "gallery": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galéria"])},
    "foods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ételek"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szobák"])},
    "93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális pekingi kacsa"])},
    "93cut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális pekingi kacsa"])},
    "137": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelleres kesudió"])},
    "145a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüzes császárhús karfiolllal"])},
    "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dong-po sertéshús"])},
    "162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sült tészta királyrákkal"])},
    "16b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pálcikán sült fokhagymás garnélarák"])},
    "83a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gung-Bao csirke"])},
    "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üvegtészta uborkával és meduzával"])},
    "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qing tian sertés"])},
    "96": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hideg lazacszelet"])},
    "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambusz szójaszószban"])},
    "99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rombuszhal"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapcsolat"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éttermünk a 1097 Budapest, Albert Flórián út 3/b szám alatt található, pár perc sétára Nagyvárad tértől. Kényelmes parkolási lehetőség van az étterem előtt, mely hétköznap este 18:00 után és hétvégén ingyenes."])},
    "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foglalás"])},
    "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asztal foglalás"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyitvatartás"])},
    "kitchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konyhánk 22:30-ig van nyitva."])},
    "everyday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindennap"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teljes cím"])}
  },
  "admin": {
    "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étlap szerkesztő"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzaadás"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megtekint"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email cím"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
    "item": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új tétel"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzaadás"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tétel szerkesztés"])},
      "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerkesztés"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angol név"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar név"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kínai név"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ár"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fajta"])}
    }
  }
}