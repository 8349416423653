<template>
  <div id="footer-bar">
    <footer class="p-4 bg-primary text-white text-left">
      <div class="container">
        <div class="row justify-content-between">
          <div>
            <h3 class="pt-4">{{ $t('contact.title') }}</h3>
            <p class="pt-3 fs-5 text-secondary">
              {{ $t('contact.reservation') }}:
            </p>
            <p class="fs-6">
              +36 1 215 1236
            </p>
            <p class="pt-3 fs-5 text-secondary">
              {{ $t('contact.opening') }}:
            </p>
            <p class="fs-6">
              {{ $t('contact.everyday') }}: 12:00 - 23:00
            </p>
            <p class="pt-3 fs-5 text-secondary">
              <a class="nav-link" href="https://hongkongrestaurant.hu/">{{ $t('hongkong') }}</a>
            </p>
            <p class="pt-4">&copy; 2022 {{ $t('name') }}</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'footer-bar',
  components: {
  }
}
</script>