import { createRouter, createWebHistory, RouterView } from 'vue-router'
import i18n from '../i18n'
import { auth } from "@/components/firebaseInit"

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}`)
}
function loadAdmin(component) {
  // '@' is aliased to src/components
  return () => import(`@/admin-views/${component}`)
}

const routes = [
  {
    path: '/',
    redirect: `/${i18n.global.locale}` 
  },
  {
    path: '/:locale',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'home',
        component: load('HomeView')
      },
      {
        path: 'menu',
        name: 'menu',
        component: load('MenuView')
      },
      {
        path: 'gallery',
        name: 'gallery',
        component: load('GalleryView')
      },
      {
        path: 'contact',
        name: 'contact',
        component: load('ContactView')
      },
      {
        path: 'menuEditor',
        name: 'menuEditor',
        component: loadAdmin('MenuEditor'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'addItem',
        name: 'addItem',
        component: loadAdmin('AddItem'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'editItem/:item_id',
        name: 'editItem',
        component: loadAdmin('EditItem'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'viewItem/:item_id',
        name: 'viewItem',
        component: loadAdmin('ViewItem'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'login',
        name: 'loginForm',
        component: loadAdmin('LoginForm'),
        meta: {
          requiresGuest: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if(to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NOT logged in
    if(!auth.currentUser) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.requiresGuest)) {
    // Check if logged in
    if(auth.currentUser) {
      next({
        path: `/${i18n.locale}/menuEditor` 
      })
    } else {
      next()
    }
  } else {
    let locale = to.params.locale
    if (!locale || (locale != 'en' && locale != 'hu' && locale != 'zh')) {
      locale = 'en'
    }
    i18n.locale = locale
    next()
  }
})

export default router